import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'
import iPhoneHorizontal from '../images/png/iPhone-Horizontal.png'
import screenVideoGame from '../videos/screen-game.mp4'
import healthyAll from '../images/png/healty-all.png'
 const Grid =  styled.div`

`;

 const Row =  styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 0;
    @media (max-width: ${devices.md}) {
         flex-direction: column;
    }
`;

 const Col =  styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: ${(props) => props.size};   
    padding: 20px 30px;
`;


const Column = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-top:100px;

    @media (max-width: ${devices.md}) {
        padding-top:20px;
    }
`

const TitleHead = styled.div`
    font-size:1.8rem;
    font-weight: 200;
    text-align: center;
    padding-top:20px;
`

const ContentBox = styled.div`
    padding: 20px 20px;

    @media (max-width: ${devices.sm}) {
        padding: 20px 0px;
    }
`

const PhoneHorizontalContainer = styled.div `
    position: relative;

    @media (max-width: ${devices.md}) {
     padding-bottom: 50px;
    }

    @media (max-width: ${devices.sm}) {
        padding-bottom: 20px;
    }
`

const PhoneHorizontal = styled.img` 
    width:100%;
    position: absolute;
`

const ScreenGameHorizontal = styled.video`
    position: absolute;
    width: 95%;
    top: 13px;
    right: 10px;
    @media (max-width: ${devices.md}) {
        position: relative;
        top: 18px;
        right: -15px;
    }
    @media (max-width: ${devices.sm}) {
        top: 10px;
        right: -8px;
    }
`

export type sizeProps = {
    size: string
};

 export default function BannerScienceSecond({size}: sizeProps) {
        return (
            <SectionWrapper>
                <Column>
                  <Row>
                    <Col size="1">
                        <PhoneHorizontalContainer>
                            <PhoneHorizontal src={iPhoneHorizontal}>
                            </PhoneHorizontal>
                            <ScreenGameHorizontal  autoPlay loop muted>
                                    <source src={screenVideoGame} type="video/mp4"/>
                            </ScreenGameHorizontal> 
                        </PhoneHorizontalContainer>
                    </Col>
                    <Col size="1">
                    <TitleHead>WHY Healthy Life?</TitleHead>
                    <ContentBox>
                    It is a long established fact that a reader
                    will be distracted by the readable content 
                    of a page when looking at its layout. The point 
                    of using Lorem Ipsum is that it has a more-or-less 
                    normal distribution of letters, as opposed 
                    to using 'Content here, content here', making
                    it look like readable English.
                    </ContentBox>
                    </Col>
                   </Row>
                   <Row>
                    <Col size="1">
                    <TitleHead>WHY Healthy Life?</TitleHead>
                    <ContentBox>
                    It is a long established fact that a reader
                    will be distracted by the readable content 
                    of a page when looking at its layout. The point 
                    of using Lorem Ipsum is that it has a more-or-less 
                    normal distribution of letters, as opposed 
                    to using 'Content here, content here', making
                    it look like readable English.
                    </ContentBox>
                    </Col>
                    <Col size="1">
                    <img src={healthyAll} style={{width:'100%'}}/>
                    </Col>
                   </Row>
                </Column>
            </SectionWrapper>
        )
    
}