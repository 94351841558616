import React from 'react'
import Layout from '../components/Layout'
import '../styles/global.scss'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { devices } from '../styles/devices'

import BannerScience from '../components/BannerScience'
import BannerScienceSecond from '../components/BannerScienceSecond'
import ContactUs from '../components/ContactUs'
import ReferenceSite from '../components/ReferenceSite'
import BoxReferenceSite from '../components/BoxReferenceSite'
import Footer from '../components/Footer'

export default function science() {
    return (
        <div className="container">
            <Helmet>
                <title>Healthy Life</title>
            </Helmet>
            <Layout>
                <BannerScience title="Lorem Ipsum has been the industry's standard dummy"/>
                <BannerScienceSecond />
                <BannerScience showImg="none" title="How does it work?"/>
                <ReferenceSite/>
                <BoxReferenceSite/>
                <ContactUs />
                <Footer/>
            </Layout>
        </div>
    )
}
