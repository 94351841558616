import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'
import downloadFile from '../images/icon/download.png'

const Row =  styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: ${devices.sm}) {
        flex-direction: column;
    }
`;

const Col =  styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: ${(props) => props.size};   
    align-items: center;
`;

const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    @media (max-width: ${devices.sm}) {
        padding-top:20px;
     }
`
const ContentBox = styled.div`
    width:80%;
    font-size: 1.2rem;
    max-width: 620px;
    padding: 10px 20px;

    @media (max-width: ${devices.sm}) {
        width:auto;
     }
`

const Boxlink = styled.div`
    max-width: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 10%) 0 4px 10px;
    cursor: pointer;
    padding: 20px 20px;
    flex-direction: column;
    
    @media (max-width: ${devices.sm}) {
       margin-bottom: 20px;
    }

`

const Boxtitle = styled.div`
    font-size:0.95rem;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
`
const BoxLists = [
    {
        icon:downloadFile,
        title:'PDF'
    },
    {
        icon:downloadFile,
        title:'PDF'
    },
    {
        icon:downloadFile,
        title:'PDF'
    },
]

export default function BoxReferenceSite() {
        return (
            <SectionWrapper>
                <Column style={{paddingTop:'50px'}}>
                <ContentBox>
                    <Row>                  
                        {BoxLists.map((item, index) => {
                            return (
                                <Col size="1">
                                     <Boxlink> 
                                    <img src={item.icon}/>
                                    <Boxtitle>{item.title}</Boxtitle>
                                    </Boxlink>
                                   
                                </Col>
                            )
                        })}
                    </Row>  
                </ContentBox>
                </Column>
            </SectionWrapper>
            
        )
}

