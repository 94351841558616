import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'
import healthyGirl from '../images/svg/healthy_girl.svg'

 const Grid =  styled.div`

`;

 const Row =  styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

 const Col =  styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: ${(props) => props.size};   
`;


const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0rem;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-top: 50px;

    @media (max-width: ${devices.sm}) {
         padding-top: 20px;
    }
`

const TitleHead = styled.div`
    font-size:3rem;
    font-weight: 200;
    width:50%;
    text-align: center;
    @media (max-width: ${devices.md}) {
        width:100%;
        font-size:1.8rem;
    }
`

const Subcontent = styled.div`
    width:80%;
    font-size: 1.2rem;
    max-width: 920px;
`
const ImgScience  = styled.img`
  display : ${(props) => props.show}; 
`
class BannerScience extends React.Component {

    render(){
        return (
            <SectionWrapper>
                <Column>
                    <TitleHead>
                     {this.props.title}
                    </TitleHead>
                </Column>
                <Column>
                    <Subcontent>
                    If you want to decrease your intake of junk food it’s important to determine what is causing you to eat it.
                     What happened just before you made the decision to eat it or purchase it? Sometimes 
                     it’s a reaction to a picture or video you saw. Sometimes these cravings seem to just appear 
                     randomly out of thin air. But these cravings don’t have to be so strong. By playing these 
                     simple games you can actually train your brain to stop having so many of these cravings. 
                     On top of that, you can train yourself to no longer be so overwhelmed by seeing pictures and videos
                      of those foods you crave so much.
                    </Subcontent>
                </Column>
                <Column>
                 <ImgScience src={healthyGirl} alt="healthy" show={this.props.showImg} />
                </Column>

                
            </SectionWrapper>
        )
    }
}export default BannerScience;