import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'


const Row =  styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: ${devices.sm}) {
        flex-direction: column;
    }
`;

 const Col =  styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: ${(props) => props.size};   
`;

const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    @media (max-width: ${devices.sm}) {
        padding-top:20px;
    }
`

const TitleHead = styled.div`
    font-size:1.8rem;
    font-weight: 200;
    text-align: center;
    padding-top:20px;
    color:#7D7878;
`

const ContentBox = styled.div`
    width:80%;
    font-size: 1.2rem;
    max-width: 920px;
    padding: 20px 20px;
`

const ContentUlBox = styled.div`
    max-width: 920px;
    display:flex;
    justify-content: flex-end;

    @media (max-width: ${devices.md}) {
       justify-content: center;
    }
    
`

const linkLists = [
    {
        linkRef: (<div>Lee, C. (2020, February 19). A tale of two reference formats. APA Style Blog. 
        <br/>https://apastyle.apa.org/blog/two-reference-formats</div>)
    },
    {
        linkRef: (<div>Lee, C. (2020, February 19). A tale of two reference formats. APA Style Blog. 
        <br/>https://apastyle.apa.org/blog/two-reference-formats</div>)
    },
]


export default function ReferenceSite() {
        return (
            <SectionWrapper>
                <Column>
                    <TitleHead>Research Reference</TitleHead>
                    <TitleHead style={{color:'#000',paddingTop:'0',fontSize:'1.2rem'}}> It is a long established fact</TitleHead>
                    <ContentBox>
                    It is a long established fact that a reader will be distracted
                    by the readable content of a page when looking at its layout
                    </ContentBox>
                </Column>
                <ContentUlBox>
                    <ul> 
                        {linkLists.map((item, index) => {
                            return (
                                <li style={{marginBottom: '.725rem'}}>
                                    {item.linkRef}
                                </li>
                            )
                        })}
                    </ul>
                </ContentUlBox>
            </SectionWrapper>    
        )
}

