import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'
import healthyGirl from '../images/svg/healthy_girl.svg'
import SubscribeModal from '../components/SubscribeModal'

 const Grid =  styled.div`

`;


const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-top: 50px;
`

const TitleHead = styled.div`
    font-size:3rem;
    font-weight: 200;
    width:50%;
    text-align: center;
    @media (max-width: ${devices.md}) {
        width:100%;
        font-size:3rem;
    }
    @media (max-width: ${devices.sm}) {
        font-size:2.5rem;
    }
`

const ContactBackground = styled.div`
    width: 100%;
    min-height: 200px;
    background: #FFF8E8; 
    display: flex;
    justify-content: center;
    align-items: center;
`
export default function ContactUs() {
        return (
            <SectionWrapper>
               <ContactBackground>
                <Column>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
                <SubscribeModal titleModal="Please Email Me" btnWidth="8.5rem" btnColor="#2ec4b6" btnBorder="1px #2ec4b6 solid" btnTitleText="Subscribe to Us" > </SubscribeModal>
                </Column>
                </ContactBackground>
            </SectionWrapper>
        )
}